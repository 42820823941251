body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: lightgray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clickpoint {
  fill: floralwhite;
  stroke-width: 3;
  stroke: black;
}

.selectbutton {
  stroke-width: 3;
  stroke: slategray;
}

.verticalpathmobile, .connectpathmobile {
  stroke-width: 8;
  fill: none;
}

.verticalpath, .connectpath {
  stroke-width: 4;
  fill: none;
}

.selectedtextrect {
  fill: lightgray;
}

.selectedpathmobile {
  stroke-width: 8;
  fill: none;
}

.selectedpathonmobile {
  stroke-width: 12;
  fill: none;
}

.selectedpath {
  stroke-width: 4;
  fill: none;
}

.selectedpathon {
  stroke-width: 6;
  fill: none;
}

.connectpath, .verticalpath {
  stroke: deepskyblue;
}

.rectchoice {
  fill: deepskyblue;
}

.dehighlightedpath {
  stroke-width: 1;
}

.highlightedpath {
  stroke-width: 6; 
}

