.App {
  text-align: center;
  background-color: lightgray;
}

.App-logo {
  height: 7vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: lightgray;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #61dafb;
  /*color: darkblue;*/
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Create a container for both divs */
.container {
  display: flex;
  justify-content: space-between; /* Distribute space between divs */
  align-items: center; /* Vertically center divs */
  height: 15vh; /* Full viewport height */
  background-color: lightgray; /* Optional background color */
  min-height: 10vh;
  flex-direction: row;
  font-size: calc(3px + 2vmin);
  color: darkblue;
}

/* Style the centered div */
.centered-div {
  margin-left: 48%;
}

/* Style the right-aligned div */
.right-aligned-div {
  margin-right: 10%;
}
